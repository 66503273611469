import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { graphql } from 'gatsby';

// Component Imports
import SEO from '../components/seo';
import Container from '../components/container';
import { Section } from '../components/page-elements';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import { D3, P1 } from '../theme/typography';
import Spacer from '../components/spacer';
import BottomCut from '../components/bottomCut';
import QAndABlock from '../components/qAndABlock';

//Styled Components

const FAQPage = ({ data }) => {
  console.log(data);
  return (
    <>
      <SEO title='FAQ' />
      <Section dark cuts={'bottomCut'}>
        <Container>
          <CenteredHeaderBlock data={data.sanityFaqPage.centeredHeaderBlock} />
        </Container>
        <BottomCut light />
      </Section>
      <Section light cuts={'topAndBottomCut'}>
        <Container>
          <QAndABlock
            data={data.sanityFaqPage.qAndAs}
            style={{ marginTop: '-196px', boxShadow: 'var(--box-shadow-xl)' }}
          />
        </Container>
        <BottomCut dark />
      </Section>
    </>
  );
};

export default FAQPage;

export const FAQPageQuery = graphql`
  {
    sanityFaqPage {
      centeredHeaderBlock {
        ...SanityCenteredHeaderBlockFragment
      }
      qAndAs {
        question
        body
        ctaButton
        ctaButtonData {
          ...SanityButtonFragment
        }
        hasVideo
        video
        poster {
          ...SanityImageFragment
        }
      }
    }
  }
`;
