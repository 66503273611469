import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { P1, ButtonText } from '../theme/typography';

import ChevronRightSVG from '../images/icons/chevron-right.inline.svg';
import Button from './buttons';
import Spacer from './spacer';
import SubPoint from './_subPoint';

const Accordion = styled.div`
  max-width: 960px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
`;

const AccordionItemHeading = styled.div`
  background: var(--blue6);
  border-top: 1px solid var(--blue5);
  cursor: pointer;
  position: relative;
`;

const AccordionItemChevron = styled(ChevronRightSVG)`
  width: 24px;
  height: 24px;
  display: inline-block;
  stroke: var(--blue2);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  flex-shrink: 0;
`;

const AccordionItemButton = styled.div`
  font-family: Poppins;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-PH);
  line-height: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--blue1);
  padding: 35px 32px 29px 32px; //Top bottom offset to account for Poppins font offset
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;

  &[aria-expanded='true'] {
    ${AccordionItemChevron} {
      transform: rotate(90deg);
    }
  }

  @media (min-width: 550px) {
    padding: 35px 56px 29px 56px; //Top bottom offset to account for Poppins font offset
  }
`;

const AccodionItem = styled.div`
  &:first-of-type {
    ${AccordionItemHeading} {
      border-top: none;
    }
  }
`;

const AccordionItemContentWrapper = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);

  &[aria-hidden='false'] {
    grid-template-rows: 1fr;
    padding-bottom: 32px;
  }

  padding: 0 32px;
  background: var(--blue6);

  @media (min-width: 550px) {
    padding: 0 56px;
  }
`;

const AccordionItemContent = styled.div`
  min-height: 0;
  overflow: hidden;
  color: var(--blue1);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const fadeIn = keyframes`
    0% { opacity: 0.2; transform: translateY(5px) }

    100% { opacity: 1 }
`;

const animationString = css`
  animation: ${fadeIn} 0.4s ease-out;
`;

const AccordionItemText = styled(P1)`
  color: var(--blue1);
  overflow: hidden;
  ${({ inView }) => inView && animationString};
  white-space: pre-wrap;
`;

const QAndABlock = ({ data, style }) => {
  const [openIndexes, setOpenIndexes] = useState([]);

  //* Every time clickedIndex changes, update activeIndex
  const handleClick = (clickedIndex) => {
    if (openIndexes[clickedIndex]) {
      let newOpenIndexes = [...openIndexes];
      newOpenIndexes[clickedIndex] = 0;
      setOpenIndexes(newOpenIndexes);
    } else {
      let newOpenIndexes = [...openIndexes];
      newOpenIndexes[clickedIndex] = 1;
      setOpenIndexes(newOpenIndexes);
    }
  };

  return (
    <Accordion style={style}>
      {data.map((item, index) => (
        <AccodionItem key={index}>
          <AccordionItemHeading
            aria-level='3'
            role='heading'
            id={`accordion-item-${index}-heading`}
          >
            <AccordionItemButton
              aria-expanded={openIndexes[index] ? `true` : `false`}
              aria-disabled='false'
              aria-controls={`accordion-content-${index}`}
              role='button'
              onClick={() => handleClick(index)}
            >
              {item.question}
              <AccordionItemChevron />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemContentWrapper
            aria-hidden={openIndexes[index] ? `false` : `true`}
            role='region'
            aria-labelledby={`accordion-item-${index}-heading`}
          >
            <AccordionItemContent>
              <AccordionItemText inView={openIndexes[index]}>
                {item.body}
              </AccordionItemText>
              {item.hasVideo && (
                <>
                  <Spacer size={32} />
                  <SubPoint
                    data={{
                      content: 'video',
                      videoTitle: '',
                      duration: '',
                      poster: item.videoData.poster,
                      video: item.videoData.video,
                    }}
                  />
                </>
              )}
              {item.ctaButton && (
                <>
                  <Spacer size={32} />
                  <ButtonWrapper>
                    <Button {...item.ctaButtonData} />
                  </ButtonWrapper>
                </>
              )}
            </AccordionItemContent>
          </AccordionItemContentWrapper>
        </AccodionItem>
      ))}
    </Accordion>
  );
};

export default QAndABlock;
